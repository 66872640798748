import React from "react"
import styled from "styled-components"

const SingleRadioButtonWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: ${({isMaxWidthBigger}) => isMaxWidthBigger ? "100px" : "80px"};
	margin-right: 12px;
	@media(min-width: 414px) {
		margin-right: 10px;
	}
	@media(min-width: 500px) {
		margin-right: 16px;
	}
	@media(min-width: 768px) {
		margin-right: 18px;
	}
	@media(min-width: 800px) {
		margin-right: 20px;
	}
`;

const SingleRadioButton = styled.div`
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	width: 45px;
	height: 45px;
	top: 4px;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.colors.formBlue};
	background-color: ${({theme}) => theme.colors.formTransparent};
	position: relative;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-weight: ${({theme}) => theme.fontWeight.bold};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
    &.three-options {	
    	width: 55px;
    	height: 55px;
    }
    @media(min-width: 768px) {
    	width: 60px;
    	height: 60px;
    }
	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.formFontDarkBlue};
	}
	@media(min-width: 1280px) {
		margin-bottom: 0;
	}
	&.active {
		border: 2px solid ${({ theme }) => theme.colors.formAlwisOrange};
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({theme}) => theme.colors.formAlwisOrangeLightOrange};
	}
`;

const RadioButtonLabel = styled.p`
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	font-size: 22px;
	line-height: 28px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const ErrorValidation = styled.p`
	font-size: 16px;
	display:none;
	color: ${({theme}) => theme.colors.formAlwisOrange};
	position: absolute;
	transform: translateY(67px);
	text-align: center;
&:nth-of-type(1){
	display:block;
	/* &:nth-last-child(2){
		display:none;
	} */
}`


const ErrorNone = styled.span`
visibility: hidden;
position:absolute;
`;

const SingleRadioButtonReduxForm = ({input, meta: {error, touched}, amountName, id, activeId, onClick: handleIdAndLabel, defaultId, isMaxWidthBigger, htmlId }) => {

	return (
		<>
		<SingleRadioButtonWrapper id={htmlId} isMaxWidthBigger={isMaxWidthBigger} key={id} onClick={() => handleIdAndLabel(id, amountName)}>
			<SingleRadioButton {...input} className={((activeId || defaultId) === id) ? "active" : ""}>
				<RadioButtonLabel>{amountName}</RadioButtonLabel>
			</SingleRadioButton>
		</SingleRadioButtonWrapper>
		{touched &&((error && <ErrorValidation> <ErrorNone> {error}</ErrorNone>pole wymagane</ErrorValidation>))}
		</>
	)
};

export default SingleRadioButtonReduxForm;
