import React from "react"
import {useSelector} from "react-redux";
import CleanThirdStep from "../components/thirdStep/CleanThirdStep";

const Step3 = () => {
	const isDataLoadFromLocalStorage = useSelector(state => state.loadDataFromLocalStorage.loadDataFromLocalstorage);
	return (
		<>
			<CleanThirdStep/>
		</>
	)
};

export default Step3
