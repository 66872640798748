export const yearsPropertyWasInsuredData = [{
	"value": 1,
	"label": "Nie była ubezpieczana"
},{
	"value": 2,
	"label": "Od 1 roku"
},{
	"value": 3,
	"label": "Od 2 lat"
},{
	"value": 4,
	"label": "Od 3 lat"
},{
	"value": 5,
	"label": "Od 4 lat"
},{
	"value": 6,
	"label": "Od 5 lat"
},{
	"value": 7,
	"label": "Od 6 lat"
},{
	"value": 8,
	"label": "Od 7 lat"
},{
	"value": 9,
	"label": "Od 8 lat"
},{
	"value": 10,
	"label": "Od 9 lat"
},{
	"value": 11,
	"label": "Od 10 lat lub dłużej"
}]

export const lastInsuranceAgencyData = [{
	"value":1,
	"label":"Aegon"
},{
	"value":2,
	"label":"Allianz"
},{
	"value":3,
	"label":"Aviva"
},{
	"value":4,
	"label":"AXA Direct"
},{
	"value":5,
	"label":"Benefia"
},{
	"value":6,
	"label":"BRE Ubezpieczenia"
},{
	"value":7,
	"label":"Compensa"
},{
	"value":8,
	"label":"ERGO Hestia"
},{
	"value":9,
	"label":"Generali"
},{
	"value":10,
	"label":"HDI"
},{
	"value":11,
	"label":"INTER Polska"
},{
	"value":12,
	"label":"InterRisk"
},{
	"value":13,
	"label":"LINK4"
},{
	"value":14,
	"label":"mBank"
},{
	"value":15,
	"label":"MTU - ERGO Hestia"
},{
	"value":16,
	"label":"mtu24.pl - ERGO Hestia"
},{
	"value":17,
	"label":"Proama"
},{
	"value":18,
	"label":"PZU"
},{
	"value":19,
	"label":"TU Europa"
},{
	"value":20,
	"label":"TUW Concordia"
},{
	"value":21,
	"label":"Uniqua"
},{
	"value":22,
	"label":"Warta"
},{
	"value":23,
	"label":"Wiener"
},{
	"value":24,
	"label":"Inny"
}]

export const floodDamageAmountData = [{
	"value": "0",
	"label": "0"
},{
	"value": "1",
	"label": "1"
},{
	"value": "2",
	"label": "2"
},{
	"value": "3+",
	"label": "3+"
}]

export const yearsPropertyWasInsuredTooltip = {
	"header": "Od ilu lat ubezpieczasz nieruchomość?",
	"description": "Jeśli masz przerwę w ubezpieczeniu nieruchomości dłuższą niż 20 dni wybierz odpowiedź \"Nie była ubezpieczana\"."
}

export const lastInsuranceAgencyTooltip = {
	"header": "Ostatni ubezpieczyciel",
	"description": "Wybierz w jakiej firmie została zawarta ostatnia polisa."
}

export const damageAmountDuringInsurancePeriodTooltip = {
	"header": "Liczba szkód w wybranym okresie",
	"description": "Weź pod uwagę szkody, które zdarzyły się w trakcie trwania ostatniej polisy."
}

export const floodDamageAmountTooltip = {
	"header": "Ilość szkód powodziowych",
	"description": "Policz szkody powodziowe, o których Ci wiadomo, nawet jeśli nie byłeś jeszcze właścicielem nieruchomości w momencie ich zaistnienia."
}
