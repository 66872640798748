import React, {useEffect, useRef, useState} from "react"
import {Header} from "../common/Header";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import {connect, useDispatch} from "react-redux"
import {setFormValueAction} from "../../state/form";
import {ContentWrapper} from "../common/ContentWrapper";
import {navigate} from "gatsby";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import {CategoryHeader} from "../common/CategoryHeader";
import {
	lastInsuranceAgencyData,
	yearsPropertyWasInsuredData,
	floodDamageAmountData,
	yearsPropertyWasInsuredTooltip,
	lastInsuranceAgencyTooltip,
	damageAmountDuringInsurancePeriodTooltip,
	floodDamageAmountTooltip
} from "../../dataSources/Page3DataSource";
import {damageAmount} from "../../dataSources/CommonDataSource"
import RadioButton from "../common/SingleRadioButton";
import {Slide} from "react-reveal";
import {
	BlueHint, ErrorMessageText, HeaderAndQuestionMarkWrapper,
	HeaderAndRadioButtonsWrapper, InvisibleWrapper,
	RadioButtonsWrapper,
	TabWrapper
} from "../sharedComponents/SharedComponents";
import CircleWithQuestionMark from "../tooltip/CircleWithQuestionMark";
import {Field, formValueSelector, reduxForm} from "redux-form";
import CustomSelectReduxForm from "../commonReduxFormComponents/CustomSelectReduxForm";
import SingleRadioButtonReduxForm from "../commonReduxFormComponents/SingleRadioButtonReduxForm";
import {sendDataToFortWebApiAction} from "../../state/sendDataToFortWebApi";

let CleanThirdStep = ({handleSubmit, value, load, pristine, reset, submitting, houseType,
	                      roofType, wallsConstructionType, propertyForRent, propertyArea, buildYearLabel,
	                      propertyType, change, city, economicActivity, assignmentToBank, yearsPropertyWasInsured,
	                      lastInsuranceAgency,
	                      damageAmountDuringInsurancePeriod,
	                      floodDamageAmount,}) => {
	const dispatch = useDispatch();

	const [yearsPropertyWasInsuredTooltipVisible, toogleYearsPropertyWasInsuredVisibility] = useState(false)
	const handleYearsPropertyWasInsuredTooltipVisibility = () => {
		toogleYearsPropertyWasInsuredVisibility(!yearsPropertyWasInsuredTooltipVisible)
		toogleLastInsuranceAgencyVisibility(false)
		toogleDamageAmountDuringInsurancePeriodVisibility(false)
		toogleFloodDamageAmountVisibility(false)
	}
	const [lastInsuranceAgencyTooltipVisible, toogleLastInsuranceAgencyVisibility] = useState(false)
	const handleLastInsuranceAgencyTooltipVisibility = () => {
		toogleLastInsuranceAgencyVisibility(!lastInsuranceAgencyTooltipVisible)
		toogleYearsPropertyWasInsuredVisibility(false)
		toogleDamageAmountDuringInsurancePeriodVisibility(false)
		toogleFloodDamageAmountVisibility(false)
	}
	const [damageAmountDuringInsurancePeriodVisible, toogleDamageAmountDuringInsurancePeriodVisibility] = useState(false)
	const handleDamageAmountDuringInsurancePeriodTooltipVisibility = () => {
		toogleDamageAmountDuringInsurancePeriodVisibility(!damageAmountDuringInsurancePeriodVisible)
		toogleYearsPropertyWasInsuredVisibility(false)
		toogleLastInsuranceAgencyVisibility(false)
		toogleFloodDamageAmountVisibility(false)
	}
	const [floodDamageAmountTooltipVisible, toogleFloodDamageAmountVisibility] = useState(false)
	const handleFloodDamageAmountTooltipVisibility = () => {
		toogleFloodDamageAmountVisibility(!floodDamageAmountTooltipVisible)
		toogleYearsPropertyWasInsuredVisibility(false)
		toogleLastInsuranceAgencyVisibility(false)
		toogleDamageAmountDuringInsurancePeriodVisibility(false)
	}


	return (
		<TabWrapper>
			<Header className="tablet-hidden">Historia ubezpieczenia</Header>
			<BlueHint className="tablet-hidden">wypełnij formularz</BlueHint>
			<ContentWrapper>
						<form onSubmit={handleSubmit}>
							<Field
								name="home[yearsPropertyWasInsured]"
								component={CustomSelectReduxForm}
								type="text"
								options={yearsPropertyWasInsuredData}
								// validate={validate.isRequired}
								selectLabel={"Liczba lat ubezpiecznia"}
								placeholder={"Wybierz ilość lat"}
								isFirst={true}
								isFirstOnPage={true}
								showCircle={true}
								isWindowVisible={yearsPropertyWasInsuredTooltipVisible}
								onClick={handleYearsPropertyWasInsuredTooltipVisibility}
								tooltipHeader={yearsPropertyWasInsuredTooltip.header}
								tooltipDescription={yearsPropertyWasInsuredTooltip.description}
								onChange={(yearsPropertyWasInsured) => {
									if(yearsPropertyWasInsured.value < 2) {
										if (lastInsuranceAgency !== null) {
											change('home[lastInsuranceAgency]', null)
										}
										if (damageAmountDuringInsurancePeriod !== null) {
											change('home[damageAmountDuringInsurancePeriod]', null);
										}
									}
								} }
							/>
							<InvisibleWrapper isVisible={yearsPropertyWasInsured?.value !== 1}>
								<Field
									name="home[lastInsuranceAgency]"
									component={CustomSelectReduxForm}
									type="text"
									options={lastInsuranceAgencyData}
									// validate={validate.isRequired}
									selectLabel={"Nazwa ostatniego zakładu ubezpieczeń"}
									placeholder={"Wybierz firmę"}
									showCircle={true}
									isWindowVisible={lastInsuranceAgencyTooltipVisible}
									onClick={handleLastInsuranceAgencyTooltipVisibility}
									tooltipHeader={lastInsuranceAgencyTooltip.header}
									tooltipDescription={lastInsuranceAgencyTooltip.description}
									// onChange={(val) => {
									// 	change('nazwa-pola', val.value)
									// }}
								/>
								<Slide top duration="300">
									<HeaderAndRadioButtonsWrapper>
										<HeaderAndQuestionMarkWrapper>
											<CategoryHeader className="radio-buttons">{(yearsPropertyWasInsured?.value === 2 || !yearsPropertyWasInsured?.value ) ? 'Liczba szkód w ostatnim roku' : ((yearsPropertyWasInsured?.value === 3) ? 'Liczba szkód w ostatnich 2 latach' : ((yearsPropertyWasInsured?.value === 4) ? 'Liczba szkód w ostatnich 3 latach' : ((yearsPropertyWasInsured?.value === 5) ? 'Liczba szkód w ostatnich 4 latach' : ((yearsPropertyWasInsured?.value === 6) ? 'Liczba szkód w ostatnich 5 latach' : ((yearsPropertyWasInsured?.value === 7) ? 'Liczba szkód w ostatnich 6 latach' : ((yearsPropertyWasInsured?.value === 8) ? 'Liczba szkód w ostatnich 7 latach' : ((yearsPropertyWasInsured?.value === 9) ? 'Liczba szkód w ostatnich 8 latach' : ((yearsPropertyWasInsured?.value === 10) ? 'Liczba szkód w ostatnich 9 latach' : (yearsPropertyWasInsured?.value === 11) ? 'Liczba szkód w ostatnich 10 latach' :'Liczba szkód powyżej 10 lat'))))))))}</CategoryHeader>
											<CircleWithQuestionMark
												showCircle={true}
												isRadioButton={true}
												isWindowVisible={damageAmountDuringInsurancePeriodVisible}
												onClick={handleDamageAmountDuringInsurancePeriodTooltipVisibility}
												tooltipHeader={damageAmountDuringInsurancePeriodTooltip.header}
												tooltipDescription={damageAmountDuringInsurancePeriodTooltip.description}/>
										</HeaderAndQuestionMarkWrapper>
										<RadioButtonsWrapper>
											{damageAmount.map(({ label, value }) => (
												<Field name="home[damageAmountDuringInsurancePeriod]"
												       id={value}
												       key={value}
												       type="radio"
												       component={SingleRadioButtonReduxForm}
												       amountName={label}
												       htmlId={'damages-last-year-' + value}
												       activeId={damageAmountDuringInsurancePeriod}
												       onClick={(val, label) => {
													       change('home[damageAmountDuringInsurancePeriod]', val)
													       // change('damageAmountDuringInsurancePeriodLabel', label)
													       console.log(val)
													       console.log(label)
													       // setFieldValue("propertyForRent", val)
													       // setFieldValue("propertyForRentLabel", label)
												       }}
												/>
											))}
										</RadioButtonsWrapper>
									</HeaderAndRadioButtonsWrapper>
									</Slide>
							</InvisibleWrapper>


							<HeaderAndRadioButtonsWrapper>
								<HeaderAndQuestionMarkWrapper>
									<CategoryHeader className="radio-buttons">Liczba szkód powodziowych w ostatnich 20 latach</CategoryHeader>
									<CircleWithQuestionMark
										showCircle={true}
										isRadioButton={true}
										isWindowVisible={floodDamageAmountTooltipVisible}
										onClick={handleFloodDamageAmountTooltipVisibility}
										tooltipHeader={floodDamageAmountTooltip.header}
										tooltipDescription={floodDamageAmountTooltip.description}/>
								</HeaderAndQuestionMarkWrapper>
								<RadioButtonsWrapper>
									{floodDamageAmountData.map(({ label, value }) => (
										<Field name="home[floodDamageAmount]"
										       id={value}
										       key={value}
										       type="radio"
										       component={SingleRadioButtonReduxForm}
										       amountName={label}
										       htmlId={'flood-damage-amount-' + value}
										       activeId={floodDamageAmount}
										       onClick={(val, label) => {
											       change('home[floodDamageAmount]', val)
											       console.log(val)
											       // setFieldValue("propertyForRent", val)
											       // setFieldValue("propertyForRentLabel", label)
										       }}
										/>
									))}
								</RadioButtonsWrapper>
							</HeaderAndRadioButtonsWrapper>
							<NavigationButtonsWrapper>
								<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"}
								                    to="/step-2"/>
								<NextStepButton type="submit"
								                isDisabled={submitting}
								                isVisible={true}
								                textBtn={"DALEJ"}/>
							</NavigationButtonsWrapper>
						</form>
			</ContentWrapper>
		</TabWrapper>
	)
};

const validate = values => {
	const errors = {
		"lead": {},
		"person": {},
		"home": {
			"address": {},
		},
	};
	if (!values?.home?.yearsPropertyWasInsured) {
		errors.home.yearsPropertyWasInsured = 'pole wymagane';
	}
	if (values?.home?.yearsPropertyWasInsured && values?.home?.yearsPropertyWasInsured?.value !== 1) {
		if (!values?.home?.lastInsuranceAgency) {
			errors.home.lastInsuranceAgency = 'pole wymagane';
		}
		if (!values?.home?.damageAmountDuringInsurancePeriod) {
			errors.home.damageAmountDuringInsurancePeriod = 'pole wymagane';
		}
	}
	if (!values?.home?.floodDamageAmount) {
		errors.home.floodDamageAmount = 'pole wymagane';
	}
	console.log(values)
	console.log(errors)
	console.log('page3blank ' + JSON.stringify(errors))
	return errors;
};

const onSubmit = (values, dispatch, formReducer) => {
	localStorage.setItem('propertyForm', JSON.stringify(values));
	// registerField('propertyForm', 'street', 'string')
	// localStorage.setItem('form-data', JSON.parse(values));
	// alert(JSON.stringify(values))
	// alert(JSON.stringify(formReducer))
	console.log(formReducer)
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(values)))
	// dispatch(togglePopupVisibility(true))
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(formReducer.values)))
	dispatch(sendDataToFortWebApiAction(values))
	navigate('/step-4')
	console.log(JSON.stringify(values))
}

CleanThirdStep = reduxForm({
	form: 'propertyForm',
	destroyOnUnmount: false,
	keepDirtyOnReinitialize: true,
	// forceUnregisterOnUnmount: true,
	validate,
	onSubmit
})(CleanThirdStep);

const selector = formValueSelector('propertyForm')
CleanThirdStep = connect(state => {
	// can select values individually
	const propertyForRent = selector(state, 'home[propertyForRent]')
	const propertyArea = selector(state, 'home[propertyArea]')
	const propertyType = selector(state, 'home[propertyType]')
	const houseType = selector(state, 'home[houseType]')
	const roofType = selector(state, 'home[roofType]')
	const wallsConstructionType = selector(state, 'home[wallsConstructionType]')
	const buildYearLabel = selector(state, 'buildYearLabel')
	const city = selector(state, 'home[address[city]]')
	const economicActivity = selector(state, 'home[economicActivity]')
	const assignmentToBank = selector(state, 'home[assignmentToBank]')
	const yearsPropertyWasInsured = selector(state, 'home[yearsPropertyWasInsured]')
	const lastInsuranceAgency = selector(state, 'home[lastInsuranceAgency]')
	const damageAmountDuringInsurancePeriod = selector(state, 'home[damageAmountDuringInsurancePeriod]')
	const floodDamageAmount = selector(state, 'home[floodDamageAmount]')

	// values: getFormValues('propertyForm')(state)
	// const favoriteColorValue = selector(state, 'favoriteColor')
	// or together as a group
	// const { firstName, lastName } = selector(state, 'firstName', 'lastName')
	return {
		propertyForRent,
		propertyArea,
		propertyType,
		houseType,
		roofType,
		wallsConstructionType,
		buildYearLabel,
		city,
		economicActivity,
		assignmentToBank,
		yearsPropertyWasInsured,
		lastInsuranceAgency,
		damageAmountDuringInsurancePeriod,
		floodDamageAmount
		// favoriteColorValue,
		// fullName: `${firstName || ''} ${lastName || ''}`
	}
})(CleanThirdStep)

export default CleanThirdStep;
